@import url(https://pro.fontawesome.com/releases/v6.0.0-beta1/css/all.css);
@import url(https://fonts.googleapis.com/css2?family=Jura:wght@500;600;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.main {
  text-align: center;
  justify-content: center;
  /* display: flex;  */
  /* padding-top: 90px; */
  /* padding-bottom: 100px; */
  height: 100vh; /* Full viewport height */
  overflow: hidden;
  background-color: #fdfdfe;
}
.sub-main {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 48%;
  width: 25%;
  box-shadow: 11px 12px 13px 12px rgb(207, 207, 207);
  margin-top: 10vh;
  padding-top: 7vh;
  margin-left: 35%;
  border-radius: 60px; 
  background-color: white;
}
.imgs {
    padding-top: 20px;
    justify-content: center;
    display: flex;
  }
.container-image {
  background-color: rgb(223, 221, 221); 
  border-radius: 150px;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 115px;
  width: 115px;
}
.profile {
  height: 100px;
  width: 100px;
  border-radius: 130px;
} 

#signIn{
    width: 150px;
    height: 40px;
    border-radius: 60px;
    box-shadow: inset 0px 0px 25px 0px #888;
    border: none;
    outline: none;
    background-color: #fff;
}

#signInPassword{
  width: 150px;
    height: 40px;
    border-radius: 60px;
    box-shadow: inset 0px 0px 25px 0px #888;
    border: none;
    outline: none;
    background-color: #fff;
}

.email{
    height: 25px;
    width: 25px;
    position: absolute;
    padding: 14px 0 0 25px;
}

.name{
    padding-left: 100px;
    font-size: 15px;
}
.input-field{
    padding-left: 100px;
    font-size: 15px;
    /* padding-right: 5px; */
}
.second-input{
    padding-top: 20px;
    position: relative; /* Establishes a positioning context for the image */
    display: inline-block; 
    
}
.eye-icon {
  position: absolute;
  top: 64%;
  right: 15px; /* Adjust as needed for spacing from the edge */
  transform: translateY(-50%); /* Center the icon vertically */
  cursor: pointer; /* Optional: indicates that the icon is clickable */
}

#loginOne{
    width: 200px;
    height: 40px;
    border-radius: 60px;
    background-color: #6071aa;
    /* background-color: #e1e2ce; */
    color: white;
    font-size: 17px;
    border: none;
    cursor: pointer;
}

#SignUpButton{
  width: 200px;
    height: 40px;
    border-radius: 60px;
    background-color: #75c69d;
    color: rgb(255, 255, 255);
    font-size: 17px;
    border: none;
    cursor: pointer;
    margin-top: 5px;
}
.login-buttonOne{
    padding-top: 15px;
}

#logout{
  background-color: transparent;
  color: #fff;
}

.link{
    font-size: 12px;
    font-weight: 400;
    padding: 5px;
    /* text-align: start; */
}
 a{
     color: #0000ff;
 }
#search{
    width: 80px;
    height: 30px;
    border-radius: 30px;
    background-color: #671b7e;
    color: white;
    font-size: 15px;
    border: none;
}

#search-input{
    width: 150px;
    height: 30px;
    border-radius: 60px;
    box-shadow: inset 0px 0px 25px 0px #888;
    border: none;
    outline: none;
    background-color: #fff;
    /* padding-top: '5px'; */
}



#issue{
  width: 180px;
   height: 30px;
   border-radius: none ;
   background-color: #f0eafd;
   color: rgb(16, 15, 15);
   font-size: 12px;
   
}
#issue:hover{
  /* color: #ffffff; */
  background-color: #e4ddf4;
}

#import_button{
   width: 100px;
   height: 30px;
   border-radius: none ;
   background-color: #d7c9f5f9;
   color: rgb(16, 15, 15);
   font-size: 13px;
}
#import_button:hover{
  /* color: #ffffff; */
  background-color: #cabcf8;
}

#upload_button{
  width: 190px;
   height: 30px;
   border-radius: none ;
   background-color: #e0dde6e1;
   color: rgb(16, 15, 15);
   font-size: 13px;
   
}
#upload_button:hover{
  /* color: #ffffff; */
  background-color: #d7d3e0;
}

th{
  background-color: #ffffff;
}
 tr:nth-child(odd) {
   background-color: #f9f9f9fe; /* Light gray background for even rows */
 }

 tr:nth-child(even) {
   background-color: #ffffff; /* White background for odd rows */
 }

 /* Optional: Add some styling for the table */
 table {
   width: 100%;
   border-collapse: collapse;
   margin-top: 20px;
   
 }

 th, td {
   /* border: 1px solid #dddddd; */
   padding: 8px;
   text-align: justify;
 }

 th {
   font-size: smaller;
   color: rgb(26, 24, 24);
 }
 td {
  font-size: smaller;
  color: rgb(25, 24, 26);
}

 .desc{
   display: flex;
   align-items: flex-start;
 }

 .required-asterisk{
   color:rgb(224, 15, 15);
 }

 .bordered-div {
  border: 1px solid rgb(227, 223, 223); /* 2px solid black border */
  margin-left: 40px;
  margin-top:45px;
  margin-right: 40px;
  padding-right: 0px; 
  /* padding-left: 40px; */
  padding: 40px;
  background-color:  #f2f4f4fe;;
  padding-top: 10px;
  /* padding-bottom: 10px; */
 
}

#rowsPerPageButton{
  background-color: rgb(235, 230, 254);
  /* color: #ffff; */
  /* background-color: rgb(207, 212, 237); */
  width: 1.8cm;
  height: 0.7cm;

}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 16px;
  gap: 16px;
}

.grid-container-elevator {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-gap: 16px;
  gap: 16px;
}

.elevator-card {
  border: 1px solid #ddd;
  padding: 16px;
  background-color: rgb(244, 246, 252);
  border-radius: 8px;
  color: #664fa7;
  margin: 8px;
  /* Add more styling as needed */
}

.hover-effect:hover {
  color: rgba(185, 10, 153, 0.659); /* Change this to your desired text color */
}

.employee_tracking {
  
  background-color:  rgb(244, 246, 252);
  display: block; /* Make the span behave like a block-level element */
  width: 600px; /* Set your desired width */
  border: 1px solid rgb(227, 223, 223); 
  margin-left: 40px;
  padding: 10px;
  margin-top: 20px;
 
}

/*********sem  Home with donut chart ***/
/* .issue_tracking{

  background-color:  rgb(244, 246, 252);
  display: block; 
  width: 600px; 
  border: 1px solid rgb(227, 223, 223); 
  margin-left: 40px;
  padding: 10px;
  margin-top: 20px;

  width: 100%; 
  max-width: 600px; 
} */

.sem-Home-container {
  display: flex;
  flex-direction: row;
  margin-top: 45px;
  margin-left: 60px;
  grid-gap: 190px;
  gap: 190px;
}

.issue_tracking_container {
  display: flex;
  flex-direction: column;
  grid-gap: 60px;
  gap: 60px;
}

.issue_tracking {
  background-color: #f4f6fc;
  width: 100%;
  max-width: 600px;
  border: 1px solid rgb(227, 223, 223);
  padding: 10px;
  margin-top: 20px;
  box-sizing: border-box;
}

.donut {
  /* margin-left: 12%; */
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .sem-Home-container {
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
  }

  .issue_tracking {
    margin-left: 0;
    width: 100%;
    max-width: 100%;
  }

  .donut {
    margin-left: 0;
    /* margin-top: 20px; */
    width: 100%;
    justify-content: center;
  }
}




/*****************************************************/

.employee_tracking {
  min-height: 25px; /* Set a minimum height or adjust as needed */
  /* padding: 10px;  */
  transition: min-height 0.3s ease; 
}
 
#navigation{
  color: #e5cff3;
}

.desc{
  display: flex;
  align-items: flex-start;
}

.link-no-underline{
  color: #e5cff3;
  text-decoration: none;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.user-name{
  color: #e5cff3;
  /* text-decoration: none; */
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  /* padding-right: '15px'; */
}

#search-keyword{
  width: 150px;
  margin-right: 8px;
}

.line {
  width: 1px;
  height: 30px; /* Adjust the height as needed */
  background-color: black;
  margin: -5px; /* Adjust the spacing as needed */
}
/***************************************************************************************/

.simple-bar-chart{
  --line-count: 10;
  --line-color: currentcolor;
  --line-opacity: 0.25;
  --item-gap: 2%;
  --item-default-color: #060606;
  
  height: 10rem;
  display: grid;
  grid-auto-flow: column;
  grid-gap: var(--item-gap);
  gap: var(--item-gap);
  align-items: end;
  padding-inline: var(--item-gap);
  --padding-block: 1.5rem; /*space for labels*/
  padding-block: var(--padding-block);
  position: relative;
  isolation: isolate;
}

.simple-bar-chart::after{
  content: "";
  position: absolute;
  inset: var(--padding-block) 0;
  z-index: -1;
  --line-width: 1px;
  --line-spacing: calc(100% / var(--line-count));
  background-image: repeating-linear-gradient(to top, transparent 0 calc(var(--line-spacing) - var(--line-width)), var(--line-color) 0 var(--line-spacing));
  box-shadow: 0 var(--line-width) 0 var(--line-color);
  opacity: var(--line-opacity);
}
.simple-bar-chart > .item{
  height: calc(1% * var(--val));
  background-color: var(--clr, var(--item-default-color));
  position: relative;
  animation: item-height 1s ease forwards
}
@keyframes item-height { from { height: 0 } }

.simple-bar-chart > .item > * { position: absolute; text-align: center }
.simple-bar-chart > .item > .label { inset: 100% 0 auto 0 }
.simple-bar-chart > .item > .value { inset: auto 0 100% 0 }


/************************3d button (+Add) *****************************/
#contactButton {
  
  padding: 0.45em;
  padding-left: 1em;
  font-size: 18px;
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  cursor: pointer;
  letter-spacing: 0.05em;
  display: flex;
  box-shadow: inset 0 0 1.6em -0.6em #714da6;
  overflow: hidden;
  position: relative;
  padding-right: 3em;

  width: 210px;
  height: 30px;
  border-radius: none ;
  background-color: #f0eafd;
  color: rgb(16, 15, 15);
  font-size: 12px;
}


#contactButton:hover {
  transform: translate(-0.05em, -0.05em);
  box-shadow: 0.15em 0.15em #e4ddf4;
  background-color: #e4ddf4;
}

#contactButton:active {
  transform: translate(0.05em, 0.05em);
  box-shadow: 0.05em 0.05em #e4ddf4;
}


#edit_icon:hover{
  transform: scale(1.3); /* Scale up the image on hover */
  z-index: 999; 
  /* background-color:#82074a */
}

#delete_icon:hover{
  /* background-color: #fae5f1; */
  transform: scale(1.3); /* Scale up the image on hover */
  z-index: 999;
}
/*****************AMC report table***************/
.report_container {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 80px;
  gap: 80px;
  /* justify-content: center; */
}

.card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 130px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card h2 {
  margin: 0;
  color: #333;
}

.card p {
  color: #666;
}

.card a {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #ece4fd;
  color: rgb(42, 40, 40);
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.card a:hover {
  background-color: #e2effd;
}

#copyright-footer{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #362a3c;
    color: #fff;
    text-align: center;
    padding: 4px 0px;
   
}

/***********************************Homepage******************************************/

html, body {
  margin: 0;
  padding: 0;
}

.Homepage{
  padding-left: 8.6%;
  padding-right: 8.6%;
  align-items: center;
  /* position: fixed; */
}
/* .Homepage-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  background-color: #edeaf0;
  color: rgb(26, 24, 24);
} */

.Homepage-header {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  background-color: #edeaf0;
  color: rgb(26, 24, 24);
}

.header-left, .header-right {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* to stack buttons vertically */
/* .header-right {
  flex-direction: column; 
} */

.home-button, .login-button {
  background-color: #664fa7;
  color: white;
  border: none;
  padding: 8px 18px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.delete-account-button {
  background-color: #d7234a;
  color: white;
  border: none;
  padding: 8px 6px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
}

.home-button:hover, .login-button:hover {
  background-color: #8855b2;
}
/* Navigation styles */
.Homepage-nav {
  background-color: #643381;
  /* background-color:#00458B; */
  padding: 10px 0;
}

.Homepage-nav ul {
  list-style: none;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
}

.Homepage-nav li {
  margin: 0 15px;
  color: rgb(251, 246, 255);
  cursor: pointer;
  font-size: 19px;
}

.Homepage-nav li:hover {
  color: #cfb6fa;
}

.link-no-underline1{
  text-decoration: none;
  /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
}

@media (max-width: 768px) {
  .Homepage-header {
    flex-direction: column;
  }

  #footer-homePage {
    flex-direction: column;
  }

  #copyright-footer-homePage {
    flex-direction: column;
  }

  .header-left, .header-right {
    margin-bottom: 10px;
  }

  .Homepage-nav ul {
    flex-direction: column;
  }

  .Homepage-nav li {
    margin: 10px 0;
  }
}

#footer-homePage{
  margin-left:8.5%;
  margin-right:8.51%;
  margin-top: 25px;
  display: flex;
  justify-content:space-evenly;
  color:#fff;
  background-color:#362a3c
}

#copyright-footer-homePage{
  margin-left:8.5%;
  margin-right:8.51%;
  display: flex;
  justify-content:center;
  align-items:center;
  color:#fff;
  background-color:#362a3c
}

/*************************Home page why sem heading and homepage cards****************************/
.why-sem {
  background-color: #9c528b;
  height: 45px;
  padding-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.why-sem h2 {
  color: white;
  font-family: 'Fraunces', serif;
  font-size: 1.5em; /* Base font size for larger screens */
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
  .why-sem {
    height: auto;
    padding: 10px; /* Adjust padding for better spacing on mobile */
  }

  .why-sem h2 {
    font-size: 1.2em; /* Adjust font size for smaller screens */
  }

}

@media (max-width: 480px) {
  .why-sem h2 {
    font-size: 1em; /* Further adjust font size for very small screens */
  }

}
          
/*******homepage cards*********/

.HomePagecard {
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 550px;
  height: 340px;
  background-color: white;
  border-radius: 10px;
  margin-bottom: 20px;
}

.HomePagecard:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.left-side-issue {
  background-image: url(/static/media/Issue_card_image.d00ff582.png);
  background-size: contain;
  background-position: center;
  border-radius: 10px 0px 0px 10px;
}

.right-side {
  margin: 20px 32px 32px 32px;
}

.left-side-tech{
  background-image: url(/static/media/Homepage_technicians_card.46e3a4f2.jpg);
  background-size: auto;
  background-position: center;
  border-radius: 10px 0px 0px 10px;
}

.left-side-notify{
  background-image: url(/static/media/notification_card.6f264d5b.jpg);
  background-size: cover;
  background-position: center;
  border-radius: 10px 0px 0px 10px;
}

.left-side-elevator{
  background-image: url(/static/media/elevator_card.a2f0945e.jpg);
  background-size: cover;
  background-position: center;
  border-radius: 10px 0px 0px 10px;
}

.right-side p{
font-family: serif;
/* color: #e30000; */
}

.centered-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0; /* Remove default margin */
  padding: 0 20px; /* Adjust padding as needed */
}

.centered-content p {
  margin: 0;
}

@media (max-width: 768px) {
  .HomePagecard {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .left-side-issue, .left-side-tech, .left-side-notify, .left-side-elevator {
    border-radius: 10px 10px 0 0;
    height: 200px;
  }

  .right-side {
    margin: 20px;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .left-side-issue, .left-side-tech {
    height: 150px;
  }

  .right-side {
    margin: 10px;
  }

  .right-side p {
    font-size: 14px;
  }

  .right-side h2 {
    font-size: 18px;
  }
}

/*******for punch line effects **************/

.title-word {
  animation: color-animation 4s linear infinite;
}

.title-word-1 {
  --color-1: #DF8453;
  --color-2: #3D8DAE;
  --color-3: #E4A9A8;
}

.title-word-2 {
  --color-1: #DBAD4A;
  --color-2: #ACCFCB;
  --color-3: #51dce6;
}

.title-word-3 {
  --color-1: #ACCFCB;
  --color-2: #E4A9A8;
  --color-3: #ACCFCB;
}

.title-word-4 {
  --color-1: #55acd1;
  --color-2: #DF8453;
  --color-3: #E4A9A8;
}

.title-word-5 {
  --color-1: #73f0e6;
  --color-2: #e4e98d;
  --color-3: #edc4de;
}
.title-word-6 {
  --color-1: #e4e98d;
  --color-2: #edc4de;
  --color-3: #73f0e6;
}

@keyframes color-animation {
  0%    {color: var(--color-1)}
  32%   {color: var(--color-1)}
  33%   {color: var(--color-2)}
  65%   {color: var(--color-2)}
  66%   {color: var(--color-3)}
  99%   {color: var(--color-3)}
  100%  {color: var(--color-1)}
}

/* Here are just some visual styles. 🖌 */


.container {
  display: grid;
  place-items: center;  
  text-align: left;
  height: 68vh;
  position: relative; /* Ensure the container is positioned relatively */
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(/static/media/lift-modernisation.f1b8a4ca.jpg);
  background-size: cover;
  filter: brightness(70%);
  z-index: -1; /* Ensure the pseudo-element is behind the content */
}


.title {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 2.3vw;
  text-transform: uppercase;
  /* backdrop-filter: brightness(80%); */
}

#homePage_list{
  color: #ae3daa;
}

#Dashboard_Homepage_Pic img{
  height: 400px;
  width: 760px;
  max-width: 760px;
}

/********************pricing **************/
/* Container Styles */
.pricing_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center cards horizontally */
  margin: 0 20px; /* Add some margin for better spacing */
}

/* Media Queries */
@media (max-width: 768px) {
  .pricing_card {
    max-width: 100%; /* Allow cards to take full width on smaller screens */
  }
}

.pricing_card{
  
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  width: 200px;
  margin: auto;
  /* text-align: center; */
  font-family: arial;

  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;   /* Centers children horizontally */
  justify-content: center; /* Centers children vertically */
}

#price{
  color: rgb(33, 138, 144);
  font-size: 20px;

  width: 100px; /* Adjust the width to desired size */
  height: 100px; /* Make sure the height is the same as the width */
  border-radius: 50%; /* Makes the square a circle */
  border: 2px solid #000; /* Adjust the border thickness and color */
  display: flex; /* Use flexbox for centering text */
  justify-content: center; /* Center text horizontally */
  align-items: center; /* Center text vertically */
  text-align: center; /* Ensure text is centered within the element */
              
}

.pricing_card button{
  
  border: none;
  outline: 0;
  padding: 12px;
  color: white;
  background-color: rgb(59, 24, 84);
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}

.pricing_card button:hover{
  opacity: 0.7;
}

#tag{
  background-color: #e9d0f6;
  padding: 10px;
  width: 100%;
  box-sizing: border-box; /* Ensures padding is included in the element's width */
  text-align: center;
}
 /************************************collapsible Home-page content*****************************************/

 .collapsible {
  background-color: #7359a1;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: #e5dee7;
  text-align: left;
  outline: auto;
  font-size: 14px;
  /* transition: background-color 0.3s; */
}

.active, .collapsible:hover {
  background-color: #926fb2;
}

.content_collapsible {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #f6f6f6;
  /* transition: max-height 0.3s ease-out, padding 0.3s ease-out; */
}

@media (max-width: 768px) {
  .grid-container {
    display: block;
  }

  #Dashboard_Homepage_Pic img {
    height: auto;
    width: 100%;
  }

  .collapsible {
    font-size: 16px;
    padding: 16px;
  }

  .content_collapsible {
    padding: 10px 16px;
  }
}

/*********************************banner cards ********************************/



.banner_cards {
  --color: rgba(30, 30, 30);
  --bgColor: rgba(245, 245, 245);
  min-height: 50vh;

  display: grid;
  align-content: center;
  grid-gap: 2rem;
  gap: 2rem;
  padding: 2rem;

  font-family: "Jura", sans-serif;
  color: var(--color);
  background: var(--bgColor);
  
}

.banner_cards h3 {
  text-align: center;
  background-color: #9c528b;
  color: #fff;
  font-family: Fraunces;
  height: 45px;
  display: flex;
  justify-content:center;
  align-items:center;
  /* max-width: 768px; */
}

@media (max-width: 768px) {

  .banner_cards h3{
    height: 65px;
    font-size: 1.2em;
    flex-wrap: wrap;
  }
}


.banner_cards ul {
  --col-gap: 2rem;
  --barH: 1rem;
  --roleH: 2rem;
  --flapH: 2rem;

  width: min(60rem, 90%);
  margin-inline: auto;

  display: flex;
  flex-wrap: wrap;

  grid-gap: var(--col-gap);

  gap: var(--col-gap);
  padding-inline: calc(var(--col-gap) / 2);

  justify-content: center;
  align-items: flex-start;
  list-style: none;
}

.banner_cards ul li {
  width: 13em;
  display: grid;
  grid-template:
    "role"
    "icon"
    "title"
    "descr";
  align-items: flex-start;
  grid-gap: 1rem;
  gap: 1rem;
  padding-block-end: calc(var(--flapH) + 1rem);
  text-align: center;
  background: var(--accent-color);
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.6) var(--roleH),
    rgba(0, 0, 0, 0.4) calc(var(--roleH) + 0.5rem),
    rgba(0, 0, 0, 0) calc(var(--roleH) + 0.5rem + 5rem)
  );
  -webkit-clip-path: polygon(
    calc(var(--col-gap) / -2 - 5px) 0,
    calc(100% + var(--col-gap) / 2 + 5px) 0,
    calc(100% + var(--col-gap) / 2 + 5px ) calc(100% - var(--flapH)),
    50% 100%,
    calc(var(--col-gap) / -2 - 5px) calc(100% - var(--flapH))
  );
          clip-path: polygon(
    calc(var(--col-gap) / -2 - 5px) 0,
    calc(100% + var(--col-gap) / 2 + 5px) 0,
    calc(100% + var(--col-gap) / 2 + 5px ) calc(100% - var(--flapH)),
    50% 100%,
    calc(var(--col-gap) / -2 - 5px) calc(100% - var(--flapH))
  );
}

/* bar */
.banner_cards ul li::before {
  content: "";
  grid-area: role;
  height: var(--barH);
  width: calc(100% + var(--col-gap));
  margin-left: calc(var(--col-gap) / -2);
  margin-top: calc(var(--roleH) / 2 - var(--barH) / 2);
  background: grey;
  z-index: -1;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.2) 30%,
    rgba(255, 255, 255, 0.1) 40%,
    rgba(0, 0, 0, 0.1) 60%,
    rgba(0, 0, 0, 0.2) 70%,
    rgba(0, 0, 0, 0.4)
  );
}

/* role */
.banner_cards ul li::after {
  content: "";
  grid-area: role;
  background: var(--accent-color);
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.2) 30%,
    rgba(255, 255, 255, 0.1) 40%,
    rgba(0, 0, 0, 0.1) 60%,
    rgba(0, 0, 0, 0.2) 70%,
    rgba(0, 0, 0, 0.4)
  );
  height: var(--roleH);
}

.banner_cards ul li .icon,
ul li .title,
ul li .descr {
  padding-inline: 1rem;
  color: white;
  text-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.banner_cards ul li .icon {
  font-size: 3rem;
}

.banner_cards ul li .title {
  font-size: 1rem;
  font-weight: 700;
}

.banner_cards ul li .descr {
  font-size: 0.83rem;
}
 
/*****************reset password******************/
/* Add this to your CSS file */

.main.blur {
  filter: blur(5px);
  pointer-events: none; /* Disable interactions with the blurred content */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it appears above other content */
}

.dialog-reset-password {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Ensure it appears above the overlay */
}

.dialog-reset-password h2 {
  margin-bottom: 20px;
}

.dialog-reset-password input {
  display: block;
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.received-passcode {
  display: flex;
  align-items: center;
  font-size: small;
}

.received-passcode input[type="checkbox"] {
  margin-right: 5px; /* Adjusts the space between the checkbox and the label */
}

.dialog-reset-password button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  box-sizing: border-box;
  /* background-color: #6f60aa; */
}

.dialog-reset-password button.send-reset-pass-mail{
  background-color: #75c69d;
}

.dialog-reset-password button.back {
  background-color: #d9645c; /* Back button color */
}

.dialog-reset-password input[type="checkbox"] {
  margin-right: 10px;
}

.dialog-reset-password label {
  display: inline-block;
  margin-bottom: 10px;
}

/********************headline for navigation flow**************************/

.sem-header-container{
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap on smaller screens */
  background-color: #664fa7;
  color: white;
  padding-top: 2px;
  overflow: hidden;
  
}

.sem-header-container h3 {
  height: 30px;
  padding-left: 15px;
  margin-top:1px;
  padding-top: 5px;
}

.sem-header-container .sem-search-container {
  margin-top: 5px;
    width: 100%; /* Take full width */
    text-align: center; /* Center the search input */
  }

/****************privacy Home tab****************/


.privacy-bordered-div {
  /* border: 1px solid rgb(227, 223, 223); 
  margin-left: 40px;
  margin-top:45px;
  margin-right: 40px;
  padding-right: 0px; 
  padding: 40px;
  background-color:  #f2f4f4fe;;
  padding-top: 10px; */

  border: 1px solid rgb(227, 223, 223);
  margin: 20px; /* Adjust margin for better spacing */
  padding: 20px; /* Adjust padding for better spacing */
  background-color: #f2f4f4fe;
 
}

/* Media Queries */
@media (max-width: 768px) {
  .bordered-div {
    margin: 10px; /* Adjust margin for smaller screens */
    padding: 10px; /* Adjust padding for smaller screens */
  }
}


.custom-bordered-div {
  /* width: 45%; */
  border: 1px solid rgb(227, 223, 223); /* 2px solid black border */
  /* margin-left: 5%; */
  margin-top:2.5%;
  /* padding-left: 40px; */
  padding: 2%;
  /* display: flex;
  justify-content:center; */
  background-color:  #f6f9ff;;
  /* padding-top: 2%; */
  /* padding-bottom: 10px; */
 
}

.customFor-bordered-div {
  width: 35%;
  border: 1px solid rgb(227, 223, 223); /* 2px solid black border */
  /* margin-left: 30%; */
  display:flex;
  /* justify-self: center; */
  /* align-self: center; */
  justify-content:center;
  /* margin-top:4%; */
  margin: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  margin-top: 5%;

  /* padding-left: 40px; */
  padding: 1.5%;
  /* display: flex;
  justify-content:center; */
  background-color:  #f6f9ff;;
  /* padding-top: 2%; */
  /* padding-bottom: 10px; */
 
}

#customField-form{
  margin-bottom: 4%;
  
}

/***********form alignment - input fields start at same level***************/

.form-group {
  display: flex;
  align-items: center;
  /* margin-bottom: 0.6em; */
}

.form-group label {
  width: 230px; /* Adjust the width as needed */
  text-align: right;
  margin-right: 10px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: auto; /* Ensures the inputs do not stretch */
  flex-shrink: 0; /* Prevent inputs from shrinking */
}
/***********form alignment - input fields start at same level***************/

.form-group2 {
  display: flex;
  align-items: center;
  /* margin-bottom: 0.6em; */
}

.form-group2 label {
  width: 100px; /* Adjust the width as needed */
  text-align: right;
  margin-right: 10px;
}

.form-group2 input,
.form-group2 select,
.form-group2 textarea {
  width: auto; /* Ensures the inputs do not stretch */
  flex-shrink: 0; /* Prevent inputs from shrinking */
}
/************add custom field 3D button **********/
#contactButton-customField {
  
  padding: 0.45em;
  padding-left: 1em;
  font-size: 18px;
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  cursor: pointer;
  letter-spacing: 0.05em;
  display: flex;
  box-shadow: inset 0 0 1.6em -0.6em #714da6;
  overflow: hidden;
  position: relative;
  padding-right: 3em;

  width: 250px;
  height: 30px;
  border-radius: none ;
  background-color: #f0eafd;
  color: rgb(16, 15, 15);
  font-size: 12px;
}


#contactButton-customField:hover {
  transform: translate(-0.05em, -0.05em);
  box-shadow: 0.15em 0.15em #e4ddf4;
  background-color: #e4ddf4;
}

#contactButton-customField:active {
  transform: translate(0.05em, 0.05em);
  box-shadow: 0.05em 0.05em #e4ddf4;
}

/*******************form alignment for custom field update form********************/

.form-group1 {
  display: flex;
  align-items: center;
  border: 1px solid #c7c3d2;
  /* justify-content: space-evenly; */
  padding: 10px;
  margin-bottom: 5px;
}

.form-group1 label {
  width: 180px; /* Adjust the width as needed */
  text-align: right;
  margin-right: 10px;
}

.form-group1 input,
.form-group1 select,
.form-group1 textarea {
  width: auto; 
  /* Ensures the inputs do not stretch */
  flex-shrink: 0; /* Prevent inputs from shrinking */
}

.delete-icon-container {
  margin-left: auto; /* Pushes the container to the extreme right */
  display: flex;
  align-items: center; /* Vertically centers the icon */
}

.instructions-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap; /* Add this to allow wrapping on smaller screens */
}

.instruction-step {
  display: inline-block;
  text-align: center;
  margin: 10px; /* Add some margin for spacing */
}

@media (max-width: 768px) {
  .instructions-container {
    flex-direction: column;
    align-items: center;
  }

  .instruction-step {
    width: 80%; /* Adjust the width as needed for mobile view */
  }
}

.Playstore_floating_footer {
  position: fixed;
  display: flex;
  align-items: center;      /* Vertically centers items */
  justify-content: center;
  bottom: 0;
  width: 24.5%;
  height: 5%;
  margin-left: 58%;
  margin-bottom: 2px;
  background-color: #71529c;
  text-align: center;
  padding: 3px;
  /* margin-top: 50px; */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.playstore-badge {
  width: 130px; /* Adjust the size of the Play Store badge */
}

